.nav-height-150{
    height: 150px;
}
.nav-logo-width{
    width: calc(75% + 222px);
}
.nav-input-line-height{
    line-height: 30px;
}
.nav-dropdown-style{

}
.nav-avatar-style{
    width: 25px;
}
.nav-dropdown-menu-style{
    inset: 48px auto auto -50px;
}
.jira-col-header{
    border-bottom: 4px solid #f5f5f5;
    overflow: visible;
}
.header-text-right{
    position: relative;top: 4px;
}
.search-bar{
    padding: 4px 12px; height: unset; margin:auto; max-width: 80% ;     font-size: 14px;
}
.float-left{
    float:left;
}
.w-18rem{
     width: 18rem;
}
.w-12rem{
     width: 12rem;
}
.navigation-width{
    width: 270px;
}
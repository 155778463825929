.issue-card-container{
    padding: 10px 5px 0px 5px;
}
.ico-story-image{
    margin-bottom: 10px;
}
.avatar-col {
    padding-right: 0px;
}
.avatar-col-container{
    min-height:48px; padding-right: 0px !important;
}
.spent-time-text{
    padding-left:0px; color: black; font-size: 11px; position: relative; top: -5px; left: 4px;
}
.spent-time-progress-bar{
    display: block; float: left;
}
.spent-time-graph{
     margin-top: 4px
}

.remaining-estimate-text{
    padding-left:0px; color: black; font-size: 11px; position: relative; top: -1px; left: 4px;
}
.remaining-estimate-progress-bar{
    display: block; float: left;
}
.remaining-estimate-graph{
    margin-top: 8px; margin-bottom: 0px;
}
.original-estimate-text{
padding-left:0px; color: black; font-size: 11px; position: relative; top: 2px; left: 4px;
}
.original-estimate-progress-bar{
    display: block; float: left;
}
.original-estimate-graph{
margin-top: 12px; margin-bottom: 0px
}